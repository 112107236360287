body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.pageContainer {
  min-height: 50vh;
}
.navbar.bg-primary {
  background-color: #00000087 !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  line-height: 26px;
}
.modal-dialog.modal-xl {
  max-width: 900px;
  background: #222831;
}
.modal.show .modal-dialog.modal-xl {
  -webkit-transform: translate(0, 5%);
  transform: translate(0, 5%);
}
.modal-xl .modal-content {
  background: #222831;
}

.navbar-brand {
  color: #ffffff !important;
}
.user-card {
  min-height: 150px;
}
.text-on-back {
  font-size: 4rem;
  margin: 0;
  line-height: 48px;
}
.text-on-back-sm {
  position: relative;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.2) !important;
  font-size: 2rem;
  margin: 0;
  line-height: 34px;
}
.profile-title {
  margin: 5px 0 10px 0;
}

body {
  margin: 0;
  padding: 0;

  /* background-origin: unset; */
}
.bg-grouppic {
  background-image: url('./static/bgr.jpg');
  background-size: contain;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.inner-page-cover {
  background-image: url('./static/bgr.jpg');
  background-size: contain;
  background-size: cover;
  background-position: 0%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-header {
  background-color: #000000 00 !important;
}
.unit-9 {
  height: 188px !important;
}
.unit-students {
  background-image: url('./static/students.jpg');
}
.unit-staffs {
  background-image: url('./static/staffs.jpg');
}
.unit-events {
  background-image: url('./static/event.jpg');
}
.unit-college {
  background-image: url('./static/college.jpg');
}
.unit-9 {
  background-size: contain;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
}

.unit-students {
  background-image: url('./static/students.jpg');
}
.h-100vh {
  min-height: 100vh;
}
.single-welcome-slide h2 {
  font-size: 90px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
}
.single-welcome-slide h5 {
  font-size: 40px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.about-us-countdown-area {
  position: relative;
  z-index: 1;
  background-color: #151853;
}

.about-us-countdown-area .about-content-text h6 {
  font-size: 14px;
  font-weight: 500;
  color: #df42b1;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #4b4d7b;
  margin-bottom: 20px;
  padding-bottom: 5px;
  display: inline-block;
}

.about-us-countdown-area .about-content-text h3 {
  font-size: 40px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.about-us-countdown-area .about-content-text p {
  color: #ffffff;
}

.about-us-countdown-area .countdown-up-area .countdown-content-text h6 {
  font-size: 14px;
  text-transform: uppercase;
  color: #df42b1;
  font-weight: 400;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.about-us-countdown-area .countdown-up-area .countdown-content-text h4 {
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.since-left {
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  padding: 20px 5px;
  width: 18%;
  margin: 0 15px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  text-align: center;
  line-height: 1;
}
.since-left span {
  margin-top: 5px;
  font-size: 14px;
  color: #e3207b;
  font-weight: 500;
  display: block;
}

.mh-promo span {
  background-color: #0bceaf;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 10px 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
}
.mh-home .hero-img img {
  margin: 0 auto;
  float: none;
  text-align: center;
  border: 20px solid rgba(0, 0, 0, 0.17);
  border-radius: 50%;
}
.mh-home .hero-img img {
  border: 20px solid rgba(0, 0, 0, 0.4);
}
.mh-header-info h2 {
  line-height: 42px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mh-header-info h4 {
  margin-bottom: 30px;
}

.mh-header-info ul {
  margin-top: 20px;
  list-style: none;
  padding: 0;
}
.mh-header-info ul li {
  margin: 10px 0;
}

.mh-header-info .social-icon {
  margin-top: 0;
}

.social-icon li {
  list-style: none;
  float: left;
  margin-bottom: 0px;
  margin-right: 8px;
}
.mh-header-info ul li {
  margin: 10px 0;
}

.mh-header-info ul li .fa {
  margin-right: 15px;
  display: inline-block;
}
.modal-content {
  background-color: #2d3238;
  background-clip: padding-box;
  border: 1px solid rgb(11, 206, 175);
  outline: 0;
}
.close {
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.card {
  background-color: #333b46;
  background-clip: border-box;
  border: 1px solid rgb(11, 206, 175);
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #424954;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 0px 0;
  padding: 20px 20px 20px 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: #222831;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

[data-reach-dialog-overlay] {
  z-index: 1030;
}
[data-reach-tab] {
  color: #555;
}
.RNIW_formGroup label {
  display: block;
}
img.userIcon {
  width: auto;
  height: 36px !important;
  border-radius: 20px;
  border: 1px solid #ffffff69;
}
.RNIW_infoText {
  text-align: center;
  margin: 32px 0;
  color: #000;
}

.semester-session {
  min-height: 100vh;
  width: 100%;
  padding: 100px 0;
  position: relative;
}
.h2 {
  font-size: 2rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  line-height: 175%;
}
.h2:after {
  position: absolute;
  content: '';
  width: 70px;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #e3207b;
}
.text-on-back {
  position: relative;
  z-index: 1;
  font-size: 5.5em;
  margin-left: -4px;
  font-weight: 900;
  color: hsla(0, 0%, 100%, 0.2) !important;
}
.profile-title {
  position: absolute;
  top: 110px;
  left: 60px;
}
