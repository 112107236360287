/* Base */
body {
  line-height: 1.7;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  font-family: Poppins, sans-serif;
  background: #222831;
}
body {
  margin: 0;

  line-height: 1.5;
  text-align: left;
}
::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

a:hover {
  text-decoration: none;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.bg-dark {
  background: #13171d !important;
}

.line-height-sm {
  line-height: 1.3;
}

.line-height-xs {
  line-height: 1;
}

.site-wrap:before {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: '';
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.offcanvas-menu .site-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.offcanvas-menu .site-wrap:before {
  opacity: 1;
  visibility: visible;
}

.btn {
  position: relative;
  top: 0;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-secondary {
  background-color: #e6e7e9;
  border-color: #e6e7e9;
  color: #000;
}

.btn.btn-sm {
  font-size: 0.9rem;
}

.btn.btn-primary {
  color: #fff;
}

.btn.btn-outline-primary:hover {
  color: #fff !important;
}

.btn.btn-black {
  background: #000 !important;
  color: #fff;
}

.btn.btn-black:hover {
  background: #333333 !important;
}

.btn.btn-white.btn-outline-white {
  border-color: #fff;
  background: transparent;
  color: #fff;
}

.btn.btn-white.btn-outline-white:hover {
  background: #fff;
  color: #000;
}

.bg-black {
  background: #000;
}

.form-control {
  height: 47px;
}

.form-control:active,
.form-control:focus {
  border-color: #e3207b;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.site-section {
  padding: 3em 0;
}

@media (min-width: 768px) {
  .site-section {
    padding: 7em 0;
  }
}

.site-section.site-section-sm {
  padding: 4em 0;
}

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000;
}

.bg-image {
  background-size: cover;
  background-repeat: no-rpeeat;
  overflow: hidden;
  background-position: center center;
}

.bg-image.center {
  background-position: top center;
}

.bg-image.fixed {
  background-position: fixed !important;
}

.bg-image.overlay,
.bg-image.overlay-primary,
.bg-image.overlay-info,
.bg-image.overlay-success,
.bg-image.overlay-warning {
  position: relative;
}

.bg-image.overlay:before,
.bg-image.overlay-primary:before,
.bg-image.overlay-info:before,
.bg-image.overlay-success:before,
.bg-image.overlay-warning:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.bg-image.overlay:before {
  background: rgba(0, 0, 0, 0.4);
}

.bg-image.overlay-primary:before {
  background: rgba(227, 32, 123, 0.9);
}

.bg-image.overlay-info:before {
  background: rgba(83, 205, 226, 0.9);
}

.bg-image.overlay-success:before {
  background: rgba(124, 189, 30, 0.9);
}

.bg-image.overlay-success:before {
  background: rgba(238, 198, 10, 0.9);
}

@media (max-width: 767.98px) {
  .display-3,
  .display-2,
  .display-4 {
    font-size: 2rem !important;
  }
}

@media (max-width: 991.98px) {
  .bg-md-height {
    height: 300px;
  }
}

@media (max-width: 767.98px) {
  .bg-sm-height {
    height: 300px;
  }
}

.text-gray-100 {
  color: #f8f9fa !important;
}

.text-gray-200 {
  color: #e9ecef !important;
}

.text-gray-300 {
  color: #dee2e6 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

.text-gray-500 {
  color: #adb5bd !important;
}

.text-gray-600 {
  color: #6c757d !important;
}

.text-gray-700 {
  color: #495057 !important;
}

.text-gray-800 {
  color: #343a40 !important;
}

.text-gray-900 {
  color: #212529 !important;
}

.row-wrap {
  margin-left: 15px;
  margin-right: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.row-items {
  height: calc(50% - 15px);
  margin-bottom: 15px;
}

.row-items.last {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .responsive-md-height {
    height: 500px !important;
    margin-bottom: 15px;
  }
}

.site-footer {
  padding: 4em 0;
}

@media (min-width: 768px) {
  .site-footer {
    padding: 7em 0;
  }
}

.site-footer p {
  color: #fff;
}

.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #fff;
}

.site-footer a {
  color: #999999;
}

.site-footer a:hover {
  color: white;
}

.site-footer ul li {
  margin-bottom: 10px;
}

.site-footer .footer-heading {
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #fff;
  position: relative;
  padding-bottom: 15px;
}

.site-footer .footer-heading:before {
  content: '';
  background: #e3207b;
  width: 40px;
  height: 2px;
  position: absolute;
  bottom: 0;
}

/* Navbar */
.site-navbar-top .site-logo {
  font-size: 2rem;
}

.site-navbar-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
}

.site-navbar-wrap a {
  color: #000;
}

.site-navbar-wrap .site-navbar-top {
  font-size: 0.8rem;
}

.site-navbar {
  margin-bottom: 0px;
  width: 100%;
  border-bottom: none;
  position: absolute;
  z-index: 99;
}

.site-navbar .site-logo {
  font-weight: 200;
}

.site-navbar .site-logo a {
  font-weight: 200;
  color: #000;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}

.site-navbar .site-navigation .site-menu .active > a {
  color: #fff;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
  padding: 10px 5px;
}

.site-navbar .site-navigation .site-menu > li > a {
  padding: 10px 10px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none !important;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #fff;
}

.site-navbar .site-navigation .site-menu > li:last-child {
  padding-right: 0;
}

.site-navbar .site-navigation .site-menu > li:last-child > a {
  padding-right: 0;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: '\e313';
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'icomoon';
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.25);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 10px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
  color: #000 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 5px 20px;
  display: block;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  color: #e3207b;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: '\e315';
  right: 20px;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  color: #e3207b;
}

.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #fff;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #e3207b;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li.active > a {
  color: #e3207b;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: 'icomoon';
  content: '\f078';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}

/* Blocks */
.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.site-blocks-cover.overlay {
  position: relative;
  opacity: 1;
}

.site-blocks-cover.overlay:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  background: #780206a3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #061161ab,
    #780206a3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #061161ab,
    #780206a3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.site-blocks-cover,
.site-blocks-cover .row {
  min-height: 600px;
  height: calc(100vh);
}

.site-blocks-cover.inner-page-cover,
.site-blocks-cover.inner-page-cover .row {
  min-height: 400px;
  height: calc(25vh);
}

.site-blocks-cover .sub-text {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.2em;
}

.site-blocks-cover h1 {
  font-weight: 900;
  color: #fff;
  font-size: 4rem;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.site-blocks-cover p {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5;
}

.site-blocks-cover .intro-text {
  font-size: 16px;
  line-height: 1.5;
}

.site-blocks-cover.subscribe h2 {
  font-size: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
}

.site-blocks-cover.subscribe p {
  font-size: 1rem !important;
  font-weight: 100;
}

.site-block-subscribe .btn {
  padding-left: 20px;
  padding-right: 20px;
}

.site-block-subscribe .form-control {
  font-weight: 300;
  border-color: #fff !important;
  height: 60px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.site-block-subscribe .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-heading {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .site-heading {
    font-size: 3rem;
  }
}

.site-heading strong {
  font-weight: 900;
}

.site-block-1 {
  padding-top: 50px;
}

@media (min-width: 992px) {
  .site-block-1 {
    padding-top: 0;
    position: absolute;
    width: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.site-block-appointment {
  background: #ece8d9 !important;
}

.site-block-appointment form {
  -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
}

.text-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

.text-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}

.site-block-feature {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

.site-block-feature .icon {
  font-size: 50px;
}

.site-block-feature h3 {
  font-size: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
}

.site-block-feature p {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
}

.site-block-feature p:last-child {
  margin-bottom: 0;
}

.site-block-feature-2 .icon {
  font-size: 50px;
  position: relative;
  line-height: 1;
}

.site-block-feature-2 h3 {
  font-size: 1.5rem;
}

.site-block-3 .row-items {
  height: calc(50% - 15px);
  margin-bottom: 30px;
}

.site-block-3 .row-items.last {
  height: calc(50% - 15px);
  margin-bottom: 0;
}

.site-block-3 .feature {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  height: 100%;
}

.site-block-3 .feature h3 {
  color: #000;
  font-size: 1.4rem;
}

.site-block-3 .feature:hover,
.site-block-3 .feature:active,
.site-block-3 .feature:focus,
.site-block-3 .feature.active {
  -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
}

.site-block-half .image,
.site-block-half .text {
  width: 100%;
}

@media (min-width: 992px) {
  .site-block-half .image,
  .site-block-half .text {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .site-block-half .image {
    margin-bottom: 50px;
  }
}

.site-block-half .text {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  .site-block-half .text {
    padding: 4rem;
  }
}

@media (min-width: 1200px) {
  .site-block-half .text {
    padding: 8rem 4rem;
  }
}

.site-block-half .bg-image {
  background-position: center center;
}

@media (max-width: 991.98px) {
  .site-block-half .bg-image {
    height: 400px;
  }
}

.site-block-half.site-block-video .image {
  position: relative;
}

.site-block-half.site-block-video .image .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 70px;
  height: 70px;
  background: #fff;
  display: block;
  border-radius: 50%;
  opacity: 1;
  color: #e3207b !important;
}

.site-block-half.site-block-video .image .play-button:hover {
  opacity: 1;
}

.site-block-half.site-block-video .image .play-button > span {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-60%, -50%);
  -ms-transform: translate(-60%, -50%);
  transform: translate(-60%, -50%);
}

.site-block-testimony img {
  max-width: 140px;
  border-radius: 50%;
}

.site-block-testimony p {
  font-size: 1.2rem;
}

.site-block-testimony.active {
  background: #fff;
  -webkit-box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px -2px rgba(0, 0, 0, 0.1);
}

.post-entry {
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e6;
}

.post-entry,
.post-entry .text {
  border-radius: 4px;
  border-top: none !important;
}

.post-entry .date {
  color: #a6a6a6;
  font-weight: normal;
  letter-spacing: 0.1em;
}

.post-entry .image {
  overflow: hidden;
  position: relative;
}

.post-entry .image img {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.post-entry:hover .image img,
.post-entry:active .image img,
.post-entry:focus .image img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.block-13,
.slide-one-item {
  position: relative;
  z-index: 1;
}

.block-13 .owl-nav,
.slide-one-item .owl-nav {
  position: relative;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.block-13 .owl-nav .owl-prev,
.block-13 .owl-nav .owl-next,
.slide-one-item .owl-nav .owl-prev,
.slide-one-item .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  padding: 20px;
  font-size: 30px;
  color: #fff;
}

.block-13 .owl-nav .owl-prev.disabled,
.block-13 .owl-nav .owl-next.disabled,
.slide-one-item .owl-nav .owl-prev.disabled,
.slide-one-item .owl-nav .owl-next.disabled {
  opacity: 0.2;
}

.block-13 .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
}

.block-13 .owl-dots {
  text-align: center;
}

.block-13 .owl-dots .owl-dot {
  display: inline-block;
  margin: 5px;
}

.block-13 .owl-dots .owl-dot > span {
  line-height: 0;
  display: inline-block;
  width: 28px;
  height: 4px;
  border: none;
  float: left;
  background: #cccccc;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.block-13 .owl-dots .owl-dot.active > span {
  background: #e3207b;
}

.slide-one-item.home-slider .owl-nav {
  position: absolute !important;
  top: 50% !important;
  bottom: auto !important;
  width: 100%;
}

.slide-one-item.home-slider .owl-prev {
  left: 10px !important;
}

.slide-one-item.home-slider .owl-next {
  right: 10px !important;
}

.slide-one-item.home-slider .owl-prev,
.slide-one-item.home-slider .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  border-radius: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  line-height: 0;
  text-align: center;
  font-size: 25px;
}

@media (min-width: 768px) {
  .slide-one-item.home-slider .owl-prev,
  .slide-one-item.home-slider .owl-next {
    font-size: 25px;
  }
}

.slide-one-item.home-slider .owl-prev > span,
.slide-one-item.home-slider .owl-next > span {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slide-one-item.home-slider .owl-prev:hover,
.slide-one-item.home-slider .owl-prev:focus,
.slide-one-item.home-slider .owl-next:hover,
.slide-one-item.home-slider .owl-next:focus {
  background: black;
}

.slide-one-item.home-slider:hover .owl-nav,
.slide-one-item.home-slider:focus .owl-nav,
.slide-one-item.home-slider:active .owl-nav {
  opacity: 10;
  visibility: visible;
}

.block-12 {
  position: relative;
}

.block-12 figure {
  position: relative;
}

.block-12 figure:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
}

.block-12 .text-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
}

.block-12 .text-overlay h2 {
  color: #fff;
}

.block-12 .text {
  position: relative;
  top: -100px;
}

.block-12 .text .meta {
  text-transform: uppercase;
  padding-left: 40px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}

.block-12 .text .text-inner {
  -webkit-box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 10px;
  margin-right: 30px;
  position: relative;
}

@media (min-width: 576px) {
  .block-12 .text .text-inner {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .block-12 .text .text-inner {
    padding: 30px 40px;
  }
}

.block-12 .text .text-inner:before {
  position: absolute;
  content: '';
  width: 80px;
  height: 4px;
  margin-top: -4px;
  background: #e3207b;
  top: 0;
}

.block-12 .text .text-inner .heading {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.block-16 figure {
  position: relative;
}

.block-16 figure .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 70px;
  height: 70px;
  background: #fff;
  display: block;
  border-radius: 50%;
  opacity: 1;
  color: #e3207b !important;
}

.block-16 figure .play-button:hover {
  opacity: 1;
}

.block-16 figure .play-button > span {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-60%, -50%);
  -ms-transform: translate(-60%, -50%);
  transform: translate(-60%, -50%);
}

.block-25 ul,
.block-25 ul li {
  padding: 0;
  margin: 0;
}

.block-25 ul li .date {
  color: rgba(255, 255, 255, 0.25);
  font-size: 0.8rem;
  display: block;
}

.block-25 ul li a .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
}

.block-25 ul li a .image img {
  border-radius: 4px;
  max-width: 100%;
  opacity: 1;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.block-25 ul li a .text .heading {
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #999999;
}

.block-25 ul li a .meta {
  color: #e3207b;
}

.block-25 ul li a:hover img {
  opacity: 0.5;
}

.block-25 ul li a:hover .text .heading {
  color: #fff;
}

#date-countdown .countdown-block {
  color: rgba(255, 255, 255, 0.4);
}

#date-countdown .label {
  font-size: 40px;
  color: #fff;
}

.next-match .image {
  width: 50px;
  border-radius: 50%;
}

.player {
  position: relative;
}

.player img {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.player .team-number {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #e3207b;
  border-radius: 50%;
  color: #fff;
}

.player .team-number > span {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.player h2 {
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.player .position {
  font-size: 14px;
  color: #b3b3b3;
  text-transform: uppercase;
}

.site-block-27 ul,
.site-block-27 ul li {
  padding: 0;
  margin: 0;
}

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px;
}

.site-block-27 ul li a,
.site-block-27 ul li span {
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.site-block-27 ul li.active a,
.site-block-27 ul li.active span {
  background: #e3207b;
  color: #fff;
  border: 1px solid transparent;
}

.block-47 .block-47-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  display: block;
}

.block-47 .block-47-image img {
  border: 5px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
}

.block-47 .block-47-quote {
  padding: 30px;
  font-weight: 300;
  background: #fff;
  border-radius: 7px;
  position: relative;
  -webkit-box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .block-47 .block-47-quote {
    margin-left: 40px;
    font-size: 1.2rem;
  }
}

.block-47 .block-47-quote:before {
  content: '';
  border: 1px solid red;
  z-index: 2;
  position: absolute;
  left: -15px;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}

.block-47 .block-47-quote .block-47-quote-author {
  font-size: 14px;
  color: #666666;
}

.site-block-check {
  padding: 0;
  margin: 0 0 30px 0;
}

.site-block-check li {
  padding: 0;
  margin: 0;
}

.site-block-check li {
  display: block;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
}

.site-block-check li:before {
  position: absolute;
  color: #7cbd1e;
  left: 0;
  top: -0.3rem;
  font-family: 'icomoon';
  font-size: 1.1rem;
  content: '\e5ca';
}

.half-sm .text,
.half-sm .text h2,
.half-sm .text small {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.half-sm .text a small {
  border-bottom: 2px solid transparent;
}

.half-sm:hover .text,
.half-sm:focus .text {
  background: #e3207b !important;
  color: #fff;
}

.half-sm:hover .text h2,
.half-sm:focus .text h2 {
  color: #fff !important;
}

.half-sm:hover .text a small,
.half-sm:focus .text a small {
  color: #fff !important;
  border-bottom: 2px solid #fff;
}

.block-37 {
  border-radius: 4px;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 200px;
}

.block-37 .image {
  height: 100%;
  background-color: black;
  /* fallback color */
  background-position: center;
  background-size: cover;
  position: relative;
  -webkit-transition: 0.8s all ease;
  -o-transition: 0.8s all ease;
  transition: 0.8s all ease;
}

.block-37 .image:before {
  content: '';
  visibility: hidden;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  top: 0;
  left: 0;
  background-color: #e3207b;
}

.block-37 .image .block-37-overlay {
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 10px;
}

.block-37 .image .block-37-overlay .icon {
  font-size: 30px;
  color: #fff;
}

.block-37:hover .block-37-overlay,
.block-37:focus .block-37-overlay {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.block-37:hover .image,
.block-37:focus .image {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.block-37:hover .image:before,
.block-37:focus .image:before {
  opacity: 0.7;
  visibility: visible;
}

.site-navbar-top .block-unit .icon {
  line-height: 0;
}

@media (max-width: 991.98px) {
  .site-navbar-top .block-unit .icon span {
    font-size: 20px !important;
  }
}

.site-navbar-top .block-unit .muted {
  color: #e9ecef;
}

.popular-domain-extension .border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.extenionlist {
  border: 1px solid red;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-domain .search-input {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #fff;
}

.search-domain .search-input::-webkit-input-placeholder {
  color: #fff;
}

.search-domain .search-input:-ms-input-placeholder {
  color: #fff;
}

.search-domain .search-input::-ms-input-placeholder {
  color: #fff;
}

.search-domain .search-input::placeholder {
  color: #fff;
}

.search-domain .btn {
  background: #e3207b;
}

.search-domain .select-wrap {
  position: relative;
}

.search-domain .select-wrap select {
  padding-right: 30px;
}

.search-domain .select-wrap .arrow-down {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.unit-2 {
  -webkit-box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.2);
}

.unit-2 .icon {
  font-size: 4rem;
}

.unit-3 {
  padding: 30px;
  border: 2px solid #e6e6e6;
  position: relative;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.unit-3:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background: #e3207b;
}

.unit-3 .unit-3-heading,
.unit-3 .unit-3-body {
  position: relative;
  z-index: 2;
}

.unit-3 .unit-3-icon-wrap {
  position: relative;
  height: 68px;
  width: 59px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 59px;
  flex: 0 0 59px;
}

.unit-3 .unit-3-svg {
  position: absolute;
  stroke: #e3207b;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.unit-3 .unit-3-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.unit-3 p {
  color: #b3b3b3;
}

.unit-3:hover,
.unit-3:focus {
  border: 2px solid transparent;
}

.unit-3:hover h2,
.unit-3:focus h2 {
  color: #fff;
}

.unit-3:hover .unit-3-body,
.unit-3:hover .unit-3-body p,
.unit-3:focus .unit-3-body,
.unit-3:focus .unit-3-body p {
  color: #fff;
}

.unit-3:hover .unit-3-svg,
.unit-3:focus .unit-3-svg {
  position: absolute;
  stroke: #fff;
}

.unit-3:hover .unit-3-icon,
.unit-3:focus .unit-3-icon {
  color: #fff;
}

.unit-3:hover:before,
.unit-3:focus:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.block-4 .nonloop-block-4 .owl-stage {
  padding-bottom: 2em;
}

.block-4 .nonloop-block-4 .owl-nav {
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: -2px;
}

.block-4 .nonloop-block-4 .owl-nav .owl-prev,
.block-4 .nonloop-block-4 .owl-nav .owl-next {
  opacity: 0.2;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: absolute;
}

.block-4 .nonloop-block-4 .owl-nav .owl-prev:hover,
.block-4 .nonloop-block-4 .owl-nav .owl-next:hover {
  opacity: 1;
}

.block-4 .nonloop-block-4 .owl-nav .owl-prev.disabled,
.block-4 .nonloop-block-4 .owl-nav .owl-next.disabled {
  display: none;
}

.block-4 .nonloop-block-4 .owl-nav .owl-prev span,
.block-4 .nonloop-block-4 .owl-nav .owl-next span {
  font-size: 30px;
}

.block-4 .nonloop-block-4 .owl-nav .owl-prev {
  left: 50px;
}

.block-4 .nonloop-block-4 .owl-nav .owl-next {
  right: 50px;
}

.block-4 .nonloop-block-4 .owl-dots {
  bottom: -40px;
  position: absolute;
  width: 100%;
  text-align: center;
}

.block-4 .nonloop-block-4 .owl-dots .owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.block-4 .nonloop-block-4 .owl-dots .owl-dot.active {
  background: #e3207b;
}

.block-38 {
  text-align: center;
}

.block-38 .block-38-header .block-38-heading {
  font-size: 18px;
  color: #000;
  margin: 0;
  font-weight: 300;
}

.block-38 .block-38-header .block-38-subheading {
  color: #b3b3b3;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}

.block-38 .block-38-header img {
  width: 90px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.block-38 .block-38-body {
  font-size: 1.1rem;
}

.unit-4 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.unit-4 li {
  display: inline-block;
  margin-right: 30px;
}

.unit-4 li:last-child {
  margin-right: 0;
}

.unit-5 {
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;
  background-position: center center;
}

.unit-5.overlay {
  position: relative;
}

.unit-5.overlay:before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}

.unit-5 > .container {
  z-index: 2;
  position: relative;
}

.unit-5 h2 {
  color: #fff;
  font-size: 3rem;
}

.unit-6 {
  color: #fff;
}

.unit-6 .sep {
  color: rgba(255, 255, 255, 0.2);
  margin: 0 10px;
}

.unit-7 {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #ffffff;
  border: 3px solid #e8e8e8;
}

.unit-7 .pricing-table__item-header {
  position: relative;
  z-index: 1;
  display: inline-block;
  top: -12px;
  margin-bottom: -12px;
  text-align: center;
  color: #ffffff;
  padding: 9px 40px;
  min-width: 200px;
  width: auto;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.unit-7 .pricing-table__item-header:before,
.unit-7 .pricing-table__item-header:after {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent #2c3640 transparent;
  z-index: 2;
}

.unit-7 .pricing-table__item-header:before {
  left: -6px;
}

.unit-7 .pricing-table__item-header:after {
  right: -6px;
}

.unit-7 .pricing-table__item-header-bg {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: -1px;
  right: -1px;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: skew(-27deg);
  -ms-transform: skew(-27deg);
  transform: skew(-27deg);
}

.unit-7 .pricing-table__item-header-bg-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: skew(27deg);
  -ms-transform: skew(27deg);
  transform: skew(27deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.unit-7 .pricing-table__item-header-bg-inner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: skew(27deg);
  -ms-transform: skew(27deg);
  transform: skew(27deg);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  background: #3e4955;
}

.unit-7 .pricing-table__item-main {
  padding: 30px;
}

.unit-7 .pricing-table__item-title {
  position: relative;
  z-index: 2;
  font-weight: 700;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

.unit-7 ul li {
  margin-bottom: 10px;
}

.unit-7 .text-mark {
  color: #000;
  font-weight: bold;
}

.unit-7.featured-pricing .pricing-table__item-header:before,
.unit-7.featured-pricing .pricing-table__item-header:after {
  border-color: transparent transparent #b91763 transparent;
}

.unit-7.featured-pricing .pricing-table__item-header-bg-inner:before {
  background: #e3207b;
}

.unit-8 .accordion-item .heading {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 10px 0;
}

.unit-8 .accordion-item .heading > a {
  padding-left: 35px;
  position: relative;
  color: #000;
}

.unit-8 .accordion-item .heading > a:before {
  width: 20px;
  height: 20px;
  line-height: 18px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 18px;
  top: 0.1em;
  left: 0;
}

.unit-8 .accordion-item .heading > a[aria-expanded='true']:before {
  font-family: 'icomoon';
  position: absolute;
  content: '\e316';
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background: #e3207b;
  color: #fff;
  border: 1px solid #e3207b;
}

.unit-8 .accordion-item .heading > a[aria-expanded='false']:before {
  content: '\e313';
  color: #ccc;
  font-family: 'icomoon';
  position: absolute;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.unit-8 .accordion-item .body-text {
  padding: 5px 0;
  padding-left: 30px;
}

.padded-multi-line-primary,
.unit-9 h2 {
  display: inline;
  background: #e3207b;
  -webkit-box-shadow: 10px 0 0 #e3207b, 0px 0 0 #e3207b;
  box-shadow: 10px 0 0 #e3207b, 0px 0 0 #e3207b;
}

.padded-multi-line-black,
.unit-9 span {
  display: inline;
  background: #000;
  -webkit-box-shadow: 10px 0 0 #000, 0px 0 0 #000;
  box-shadow: 10px 0 0 #000, 0px 0 0 #000;
}

.unit-9 {
  background-size: cover;
  background-position: center center;
  background-repeat: none;
  height: 300px;
  position: relative;
  display: block;
  overflow: hidden;
}

.unit-9 .image {
  background-size: cover;
  background-position: center center;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  height: 300px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.unit-9.no-height {
  height: auto;
}

.unit-9.no-height .image {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.unit-9 .unit-9-content {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  position: absolute;
  bottom: 0;
}

.unit-9 h2 {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}

.unit-9 span {
  background: #000;
  display: block;
  color: #fff;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 11px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.unit-9:hover .image {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.site-section-heading h2 {
  font-size: 2rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
}

.site-section-heading.w-border h2 {
  position: relative;
  padding-bottom: 20px;
  display: inline-block;
}

.site-section-heading.w-border h2:after {
  position: absolute;
  content: '';
  width: 70px;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #e3207b;
}

.site-section-heading.w-border.text-center h2:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.backgroundimg,
.site-block-retro .col1,
.site-block-retro .col2 > a {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.site-block-retro .col1,
.site-block-retro .col2 {
  width: 48%;
}

@media (max-width: 767.98px) {
  .site-block-retro .col1,
  .site-block-retro .col2 {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .site-block-retro .col1 {
    height: 300px;
    margin-bottom: 8%;
  }
}

.site-block-retro .col2 > a {
  display: block;
  height: 300px;
}

.site-block-retro .col2 > a:first-child {
  margin-bottom: 8%;
}

.pill {
  border-radius: 30px;
}

.team-member {
  position: relative;
  cursor: pointer;
  float: left;
  width: 100%;
  overflow: hidden;
  min-height: 250px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  padding: 10px;
  border: 1px solid rgb(11, 206, 175);
}

.team-member img {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.team-member:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(227, 32, 123, 0.8);
  z-index: 2;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.team-member .text {
  top: 75%;
  text-align: center;
  position: absolute;
  padding: 20px;
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  width: 100%;
  color: #fff;
}

.team-member:hover:before,
.team-member:focus:before,
.team-member:active:before {
  opacity: 1;
  visibility: visible;
}

.team-member:hover img,
.team-member:focus img,
.team-member:active img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.team-member:hover .text,
.team-member:focus .text,
.team-member:active .text {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
  z-index: 4;
}

.social-icon span {
  font-size: 20px;
}
.social-icon li a {
  background-color: #0bceaf;
  color: #fff;
  margin-right: 10px;
  padding: 15px;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  line-height: 10px;
  padding: 9px;
  font-size: 19px;
  text-align: center;
}

.social-icon:hover {
  color: #eec60a !important;
  background: #cf1a6f;
}

.podcast-entry {
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.1);
}

.podcast-entry .sep {
  margin-left: 5px;
  margin-right: 5px;
  color: #ccc;
}

.podcast-entry .image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 300px;
}

@media (min-width: 768px) {
  .podcast-entry .image {
    width: 300px;
    height: auto;
  }
}

.podcast-entry .text {
  width: 100%;
  padding: 40px;
}

@media (min-width: 768px) {
  .podcast-entry .text {
    /* width: calc(100% - 300px);*/
  }
}

.mejs__container {
  width: 100% !important;
}

form .form-control {
  background: none;
  color: #fff !important;
}

form .form-control:active,
form .form-control:focus {
  background: none;
}

figure img {
  margin-bottom: 10px;
}

figure figcaption {
  font-size: 0.8rem;
  font-style: italic;
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}

.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}

.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}

.comment-list li .vcard {
  width: 80px;
  float: left;
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}

.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}

.comment-list li .comment-body h3 {
  font-size: 20px;
  color: #fff;
}

.comment-list li .comment-body .meta {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ccc;
}

.comment-list li .comment-body .reply {
  padding: 5px 10px;
  background: #e3207b;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}

.comment-list li .comment-body .reply:hover {
  color: #fff;
  background: #e3207b;
}
